import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel'



const Slider = ({ sliders }) =>
{
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  return (
    <div style={{ display: 'flex' }} className='h-96'>
      <Carousel
        withIndicators
        height='100%' style={ { flex: 1 } }
        plugins={ [ autoplay.current ] }
        onMouseEnter={ autoplay.current.stop }
        onMouseLeave={ autoplay.current.reset }
      >
        { sliders && sliders.map(item => (
          <Carousel.Slide>
            <img className='w-full h-auto' key={ item._id } src={ `${ process.env.REACT_APP_API_URL }/api/v1/uploads/${ item._id }` } alt={ `Slider ${ item._id }` } />
          </Carousel.Slide>
        )) }
      </Carousel>
    </div>

  )
}

export default Slider