import { useContext, useEffect, useState } from 'react';
import { FaWhatsapp, FaFacebook, FaInstagram, FaPhone, FaTwitter, FaYoutube, FaPinterest, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { Box, Text, ActionIcon } from '@mantine/core';
import SettingContext from '../../context/Setting/settingContext';
import SocialNetworks from '../SocialNetworks';
import dayjs from 'dayjs';
const Footer = () =>
{
  const { settings } = useContext(SettingContext);
  const [ localSettings, setLocalSettings ] = useState({
    socialNetworks: [],
    branches: [],
    privacyStatement: "",
    contactEmail: "",
    whatsapps: []
  });

  useEffect(() =>
  {
    if (settings) {
      setLocalSettings(settings);
    }
  }, [ settings ]);

  return (
    <Box>
      <Box className='grid grid-cols-1 sm:grid-cols-2 border-t py-2 px-2 text-left sm:text-center'>
        <Box className='px-4'>
          <Text className='text-md' fw="bold">Redes sociales</Text>
          <SocialNetworks localSettings={ localSettings } />
        </Box>
        { localSettings.branches && localSettings.branches.length > 0 && (
          <Box className='px-4'>
            <Text className='text-md' fw="bold">Ubicación</Text>
            <Text>{ localSettings.branches[ 0 ]?.address }</Text>
            { localSettings.branches[ 0 ]?.phone1 && (
              <Box className='flex flex-row gap-1 items-center sm:justify-center'>
                <FaPhone  size="10px"  />
                <Text>{ localSettings.branches[ 0 ].phone1 }</Text>
              </Box>
            ) }
            { localSettings.branches[ 0 ]?.phone2 && (
              <Box className='flex flex-row gap-1 items-center sm:justify-center'>
                <FaPhone size="10px" />
                <Text>{ localSettings.branches[ 0 ].phone2 }</Text>
              </Box>
            ) }

            <Box>
              <Text className='text-md' fw="bold">Horarios</Text>
              <Text>{localSettings.branches[0].schedule1}</Text>
              <Text>{localSettings.branches[0].schedule2}</Text>
            </Box>
          </Box>
        ) }
      </Box>
      <Text className='text-center my-4'>Derechos reservados Sweet Éclair {dayjs().format('YYYY')}</Text>
    </Box>
  )
}

export default Footer