import React, { useState } from 'react';
import { currencyFormat } from 'simple-currency-format';
import { HiChevronDown, HiChevronUp } from 'react-icons/hi';
import { Title, Text, ActionIcon, Divider, Box } from '@mantine/core';

const MenuSection = ({ title, items = [] }) => {
  const [show, setShow] = useState(true);

  // Asegúrate de que 'items' sea siempre un arreglo.
  const itemList = Array.isArray(items) ? items : [];

  return (
    <div className='border rounded-lg w-auto mx-2'>
      <Box className='flex flex-row justify-between item-center gap-2 px-2 py-2'>
        <Title order={2} className='text-center pb-2 font-normal'>{title}</Title>
        <ActionIcon variant="subtle" color='blue' size='md' onClick={() => setShow(!show)}>
          {show ? <HiChevronUp className='text-2xl'/> : <HiChevronDown className='text-2xl'/>}
        </ActionIcon>
      </Box>
      {show && <Divider />}
      {show && (itemList.length > 0 ? itemList.map(item => (
        <Box key={item._id} className="flex flex-row items-start justify-between px-2 py-2 border-b border-dotted mx-2">
          <Box>
            <Text className='text-lg font-semibold'>{item.name}</Text>
            <Text fz="xs" fs='italic' mx="4px" className='text-md font-normal'>{item.description}</Text>
          </Box>
          <Text mx="sm" fw="bold" className='text-right font-bold'>
            {currencyFormat(item.price, 'es-MX', 'MXN', 2)}
          </Text>
        </Box>
      )) : <Text mx="sm" my="sm" fs="initial" fz="sm" className='text-center py-2'>Por el momento no tenemos productos disponibles</Text>)}
    </div>
  );
};

export default MenuSection;
